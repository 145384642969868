import request, { ResponsePageableData } from '@/libs/request'

/** 户型设施列表 */
export const getRoomFacilityList = (data?) => request<ResponsePageableData>({ url: '/adminapi/RoomFacility/getRoomFacilityList', data })

/** 户型设施新增/编辑 */
export const editRoomFacility = (data?) => request<ResponsePageableData>({ url: '/adminapi/RoomFacility/editRoomFacility', data })

/** 户型设施删除 */
export const delRoomFacility = (data?) => request<ResponsePageableData>({ url: '/adminapi/RoomFacility/delRoomFacility', data })
