import request, { ResponsePageableData } from '@/libs/request'

/** 酒店政策列表 */
export const getRoomPolicyList = (data?) => request<ResponsePageableData>({ url: '/adminapi/RoomPolicy/getRoomPolicyList', data })

/** 酒店政策新增/编辑 */
export const editRoomPolicy = (data?) => request<ResponsePageableData>({ url: '/adminapi/RoomPolicy/editRoomPolicy', data })

/** 酒店政策删除 */
export const delRoomPolicy = (data?) => request<ResponsePageableData>({ url: '/adminapi/RoomPolicy/delRoomPolicy', data })
