

































































































































































import Vue from 'vue'
import { getOssUrl } from '@/api/common'
import { getActivityList } from '@/api/activity'
import SelectActivity from '@/components/select/select-activity.vue' //户型标签选择器
import SelectRoomLabel from '@/components/select/select-room-label.vue' //户型标签选择器
import SelectRoomFacility from '@/components/select/select-room-facility.vue' //户型设置
import SelectRoomPolicy from '@/components/select/select-room-policy.vue' //户型政策
import SelectHotel from '@/components/select/select-hotel.vue' //酒店选择器
import SelectRoomtypeType from '@/components/select/select-room-type-type.vue'
import UploadImage from '@/components/upload/upload-image.vue'
import UploadImageOneOne from '@/components/upload/upload-imageOneOne.vue'
import UploadImageTwoone from '@/components/upload/upload-imageTwoOne.vue'
import { useTable } from '@/components/table/table-hook'
import { getRoomList, editRoom, delRoom, createRoomRow } from '@/api/room'
import { getTreeName } from '@/libs/vue-tools/index'
import { cloneDeep } from 'lodash'
import { getAdminInfo } from '@/api/admin'
import { export_json_to_excel } from '@/vendor/Export2Excel'
import admin from '@/store/modules/admin'
export default Vue.extend({
	components: {
		UploadImage,
		UploadImageTwoone,
		SelectActivity,
		SelectRoomLabel,
		SelectRoomFacility,
		SelectRoomPolicy,
		SelectHotel,
		SelectRoomtypeType,
		UploadImageOneOne,
	},
	data() {
		return {
			baseUrl: '',
			oldBaseUrl: '',
			adminInfo: {},
			editRoom,
			...useTable({
				name: '户型',
				formdata: createRoomRow(),
				query: {
					/** 筛选城市ID */
					city_id: 0,
				},
				getData: (query) =>
					getRoomList(query).then((res) => {
						// 这里演示对拿到的数据追加一些自定义属性
						res.data.rows.forEach((it) => {
							it._custom_name = '自定义的属性'
							// it.cover_image = it.cover_image

							// console.log(JSON.parse(it.cover_image))
							// it.cover_image = JSON.parse(it.cover_image)[0]
						})
						return res.data
					}),
				delData: (row) => delRoom({ id: row.id }),
			}),
			activityList: [],
			roomList: [] as any,
		}
	},

	methods: {
		formatJson(filterVal, jsonData) {
			return jsonData.map((v) => filterVal.map((j) => v[j]))
		},
		inportexcel() {
			//兼容ie10哦！
			require.ensure([], () => {
				const tHeader = ['序号', '封面图', '名称', '类型', '原价', '价格', '参与活动'] //将对应的属性名转换成中文
				// const tHeader = [];

				const filterVal = ['id', 'cover_image', 'name', 'type_id', 'original_price', 'price'] //table表格中对应的属性名
				const list = this.roomList
				const data = this.formatJson(filterVal, list)
				export_json_to_excel(tHeader, data, '列表excel')
			})
		},
		getTreeName,
		getOssUrl() {
			getOssUrl().then((res) => {
				this.baseUrl = res.data.domain
				this.oldBaseUrl = res.data.old_domain
			})
		},
		onSubmit(row) {
			let param = row
			let base_url_length = this.baseUrl.split('').length
			if (param.cover_image.indexOf(this.baseUrl) !== -1) {
				param.cover_image = param.cover_image.slice(base_url_length)
			}
			if (param.detail_images != null) {
				let detailImage = [] as any
				param.detail_images.forEach((el) => {
					if (el.indexOf(this.baseUrl) !== -1) {
						el = el.slice(base_url_length)
						if (el.indexOf(this.baseUrl) !== -1) {
							el = el.slice(base_url_length)
						}
					}
					detailImage.push(el)
				})
				param.detail_images = detailImage // 多图
			}
			param.label_ids = JSON.stringify(param.label_ids) //标签
			param.facility_ids = JSON.stringify(param.facility_ids) //设施
			param.policy_ids = JSON.stringify(param.policy_ids) //政策
			param.activity_ids = JSON.stringify(param.activity_ids) //活动
			param.detail_images = JSON.stringify(param.detail_images) //详情图
			// param.cover_image = param.cover_image[0] //详情图

			return editRoom(param).then(() => this.$message.success('保存成功') && this.tableGetData())
		},
		edit(row) {
			console.log(row, '222')

			const params = cloneDeep(row)
			if (row.detail_images.length > 0) {
				params.detail_images = row.detail_images ? JSON.parse(row.detail_images) : []
			}
			if (row.cover_image != '') {
				params.cover_image = row.cover_image
			}
			//params.cover_image = row.cover_image ? JSON.parse(row.cover_image) : []
			;(this.$refs.formDialogRef as any).open(params)
		},
	},
	inject: ['oss56'],
	created() {
		this.getOssUrl()
		getAdminInfo().then((res) => {
			this.adminInfo = res.data
			console.log(this.adminInfo)
		})

		getRoomList().then((res) => {
			// 这里演示对拿到的数据追加一些自定义属性
			this.roomList = res.data.rows
			this.roomList.forEach((it) => {
				// console.log(it)
				// it._custom_name = '自定义的属性'
				it.original_price = it.original_price / 100
				it.price = it.price / 100
				if (it.type_id == 1) {
					it.type_id = '全日房'
				} else if (it.type_id == 1) {
					it.type_id = '日租房'
				} else {
					it.type_id = '-'
				}
			})
			return this.roomList
		}),
			this.tableGetData()

		getActivityList().then((res) => {
			;(this as any).activityList = res.data.rows
		})
		console.log(this)
	},
})
