









































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { getRoomFacilityList, editRoomFacility, delRoomFacility } from '@/api/room-facility'
export default Vue.extend({
	data() {
		return {
			editRoomFacility,
			...useTable({
				name: '户型设施',
				formdata: {
					/** ID */
					id: 0,
					/** 名称 */
					name: '',
					/**标题 */
					title: undefined,
				},
				getData: (query) => getRoomFacilityList(query).then((res) => res.data),
				delData: (row) => delRoomFacility({ id: row.id }),
			}),
		}
	},
	created() {
		this.tableGetData()
	},
})
